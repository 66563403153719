window.cpexLoaderVersion = '3.1';

// TODO: kontrola skinu pres 200/30X + sirku
// muze se provadet kontrola pres to zda server odpovedel spravne ci test cpexSkin loaderem
var CPEX_testSourceCalled_Loader = CPEX_testSourceCalled_Loader || false;
if (window.top.location.href.indexOf("cpexloadersrc=test") > -1 && !CPEX_testSourceCalled_Loader) {
    CPEX_testSourceCalled_Loader = true;
    var ppas = document.createElement('script');
    ppas.async = true;
    ppas.type = 'text/javascript';
    ppas.src = 'https://test.cpex.cz/loader/v2/cpex-loader.js';
    var node = document.getElementsByTagName('script')[0];
    node.parentNode.insertBefore(ppas, node);
} else {
    window.cpexLoader = window.cpexLoader || {};
    /* If set - load settings */
    window.cpexLoader.scriptsToLoadPath = window.cpexLoader.scriptsToLoadPath || undefined;
    window.cpexLoader.scriptsToLoad = window.cpexLoader.scriptsToLoad || [];

    window.cpexLoader.options = {
        maxWaitTime: {
            cpexSkinObject: 480,
            'pbjs.setAdUnits': 15000
        },
        scriptsLoaded: {
            'cpex-skin.js': false
        }
    };
    window.cpexLoader.addedEventListeners = {};
    window.cpexLoader.addedEventListenersTimeouts = {};
    window.cpexLoader.addedEvents = {};
    window.cpexLoader.useSequential = window.cpexLoader.useSequential || (function () {
        if (
            (typeof cpexLoader.useSequential !== 'undefined' && cpexLoader.useSequential)
            ||
            (window.top.location.href.indexOf("usesequential=1") > -1)
        ) {
            return true;
        } else {
            try {
                return !new CustomEvent('test');
            } catch (e) {
                return false;
            }
        }
    })();

    window.cpexLoader.debug = window.cpexLoader.debug || (window.top.location.href.indexOf("debug=1") > -1);
    window.cpexLoader.cpexsrc = (window.top.location.href.indexOf("cpexsrc") > -1);
    window.cpexLoader.getParam = function (name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) {
            regex = new RegExp("[#&]" + name + "(=([^&#]*)|&|#|$)");
            results = regex.exec(url);
            if (!results) {
                return null;
            }
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    };

    window.cpexLoader.waitFor = function (eventType, callback, options) {
        if (typeof callback !== 'function') {
            this.error('waitFor - missing callback');
            return;
        }

        if (typeof eventType !== 'string') {
            this.error('waitFor - missing eventType');
            return;
        }

        /*
        * TODO - dodelat timout pro jednotlive eventy - aby napr skin nezapricinil nefuncnost HB
        */
        if (typeof this.options !== 'undefined'
            && typeof this.options.maxWaitTime !== 'undefined'
            && typeof this.options.maxWaitTime[eventType] !== 'undefined'
        ) {
            if (typeof this.addedEventListenersTimeouts[eventType] === 'undefined') {
                this.addedEventListenersTimeouts[eventType] = [];
            }
            this.addedEventListenersTimeouts[eventType].push(
                setTimeout(function () {
                    window.cpexLoader.warn(eventType + ' timeout fired');
                    callback();
                }, +this.options.maxWaitTime[eventType])
            );
        }
        if (!options) {
            options = { once: true };
        }
        //TODO - check vlozenych eventu a pak pripadne volat rovnou
        this.log('addEventListener', eventType);
        this.addEventListener(eventType, callback, options);
    };

    window.cpexLoader.elem = (function () {
        return window.document.createElement('div');
    })();

    window.cpexLoader.removeEventListener = function (eventType, callback) {
        this.elem.removeEventListener(eventType, callback);
        this.decreaseEventListenersCount(eventType);
    };

    window.cpexLoader.decreaseEventListenersCount = function (eventType) {
        this.log('decrease for ' + eventType);
        if (typeof this.addedEventListeners[eventType] !== 'undefined'
            && this.addedEventListeners[eventType]['count'] > 0
        ) {
            this.addedEventListeners[eventType]['count']--;
        }
    };

    window.cpexLoader.addEventListener = function (eventType, callback, options) {
        this.log('addEventListener adds: ' + eventType);
        if (typeof this.addedEventListeners[eventType] === 'undefined') {
            this.addedEventListeners[eventType] = {
                count: 0,
                onceCount: 0
            };
        }
        this.addedEventListeners[eventType].count++;
        if (typeof options !== 'undefined'
            && typeof options.once !== 'undefined'
            && options.once) {
            this.addedEventListeners[eventType].onceCount++;
        }
        this.elem.addEventListener(eventType, callback, options);

        if (typeof window.cpexLoader.addedEvents[eventType] !== 'undefined') {
            try {
                this.log('calls dispatchEvent buffer for ' + eventType);
                var buffer = window.cpexLoader.addedEvents[eventType];
                window.cpexLoader.addedEvents[eventType] = undefined;
                var l = buffer.length;

                for (var i = 0; i < l; i++) {
                    window.cpexLoader.dispatchEvent(buffer[i]);

                    if (typeof options !== 'undefined'
                        && typeof options.once !== 'undefined'
                        && options.once) {
                        this.decreaseEventListenersCount(eventType);
                        break;
                    }
                }
            } catch (e) {
                this.log(e);
            }
        }
    };

    window.cpexLoader.dispatchEvent = function (event) {
        if (
            (
                typeof window.cpexLoader.addedEventListeners[event.type] === 'undefined'
                || window.cpexLoader.addedEventListeners[event.type].count < 1
            )
        ) {
            this.log('adds ' + event.type + ' to dispatchEvent buffer');
            if (typeof window.cpexLoader.addedEvents[event.type] === 'undefined') {
                window.cpexLoader.addedEvents[event.type] = [];
            }
            this.log('addedEvents new event for ' + event.type);
            window.cpexLoader.addedEvents[event.type].push(event);
        } else {
            this.log('dispatchEvent for ' + event.type);
            this.elem.dispatchEvent(event);
        }

        if (typeof event.type !== 'undefined'
            && typeof this.addedEventListenersTimeouts !== 'undefined'
            && typeof this.addedEventListenersTimeouts[event.type] !== 'undefined'
        ) {
            for (var i = 0; i < this.addedEventListenersTimeouts[event.type].length; i++) {
                clearTimeout(this.addedEventListenersTimeouts[event.type][i]);
            }
            this.addedEventListenersTimeouts[event.type] = undefined;
        }

    };

    window.cpexLoader.emitEvent = function (name, data) {
        this.dispatchEvent(new CustomEvent(name, data));
    };

    window.cpexLoader.log = function () {
        if (this.debug) {
            console.log.apply(this, arguments);
        }
    };

    window.cpexLoader.warn = function () {
        if (this.debug) {
            console.warn.apply(this, arguments);
        }
    };

    window.cpexLoader.error = function (message) {
        if (this.debug) {
            console.error.apply(this, arguments);
        } else {
            if (!message) {
                message = '';
            }

            if (typeof message.message === 'string') {
                message = message.message;
            }

            var errorMessageObject = {
                type: 'loader',
                errorMessage: message,
                url: window.location.href,
                version: window.cpexLoaderVersion
            };

            var urlParams = "";
            for (var key in errorMessageObject) {
                if (urlParams !== "") {
                    urlParams += "&";
                }
                urlParams += key + "=" + encodeURIComponent(errorMessageObject[key]);
            }

            var xmlhttp = new XMLHttpRequest();
            xmlhttp.open("POST", "https://log.cpex.cz/log.php", true);
            xmlhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xmlhttp.send(urlParams);
        }
    };

    window.cpexLoader.checkCalledScripts = function () {
        try {
            var keys = [];
            if (typeof cpexLoader.options.scriptsLoaded !== 'undefined') {
                keys = Object.keys(cpexLoader.options.scriptsLoaded);
            }
            return function (url) {
                if (!keys) {
                    return;
                }
                for (var i = 0; i < keys.length; i++) {
                    if (url.indexOf(keys[i]) > -1) {
                        cpexLoader.options.scriptsLoaded[keys[i]] = true;
                    }
                }
            };
        } catch (e) {
            window.cpexLoader.error(e);
            return function () { };
        }
    };

    window.cpexLoader.checkCalledScripts(); // possibly not needed, test out

    window.cpexLoader.loadScript = function (url, callback) {
        var thatCpexLoader = this;
        if (this.cpexsrc) {
            var p = this.getParam('cpexsrc');
            var testPath = 'test.cpex.cz';
            if (p === 'test') {
            } else if (p === 'stage') {
                testPath = 'cdn.cpex.cz/stage'
            } else if (p === 'localhost') {
                testPath = 'localhost:8080';
            } else {
                testPath = p;
            }
            url = url.replace('cdn.cpex.cz', testPath);
        }
        this.log(url + ' init');

        window.cpexLoader.checkCalledScripts(url);

        var s = document.createElement("script");
        document.getElementsByTagName("head")[0].appendChild(s);
        s.src = url;
        s.type = "text/javascript";
        s.async = !1;
        if (typeof callback === 'function') {
            if (s.readyState) { // IE, incl. IE9
                s.onreadystatechange = function () {
                    if (s.readyState === "loaded" || s.readyState === "complete") {
                        s.onreadystatechange = null;
                        callback();
                    }
                };
            } else {
                s.onload = function () { // Other browsers
                    thatCpexLoader.log(url + ' loaded script: ', performance.now());
                    callback();
                };
            }
        }
        s.onerror = function (event) {
            cpexLoader.error('load error ' + url);
        }
    };

    window.cpexLoader.loadScriptsSequential = function (urls, b) {
        var thatCpexLoader = this;

        try {
            if (typeof urls === 'undefined') {
                return
            }
            if (typeof urls === 'string') {
                this.loadScript(urls, b);
            }
            var c = 0;
            var toDo = [];

            function count() {
                c--;
                if (c < 0) {
                    if (toDo.length < 1) {
                        if (typeof b === 'function') {
                            b();
                        }
                    } else {
                        thatCpexLoader.loadScriptsSequential(toDo, b);
                    }
                }
            }

            if (Array.isArray(urls) && urls.length > 0) {
                var url = urls.shift();
                toDo = urls;
                if (typeof url === 'function') {
                    url();
                    count();
                }
                if (typeof url === 'string') {
                    this.loadScript(url, count);
                } else {
                    c = url.length - 1;
                    for (var i = 0; i < url.length; i++) {
                        if (typeof url[i] === 'function') {
                            url[i]();
                            count();
                        } else {
                            this.loadScript(url[i], count);
                        }
                    }
                }
            } else {
                this.error('Loader: empty scriptsToLoad');
            }
        } catch (e) {
            this.error(e);
        }
    };

    window.cpexLoader.loadScriptsAsync = function (urlsInput, b) {
        try {
            if (typeof urlsInput === 'undefined') {
                return;
            }
            if (typeof urlsInput === 'string') {
                this.loadScript(urlsInput, b);
            }
            if (Array.isArray(urlsInput) && urlsInput.length > 0) {
                var urls = urlsInput.slice();

                while (urls.length > 0) {
                    var url = urls.shift();

                    if (typeof url === 'function') {
                        url();
                    } else if (Array.isArray(url)) {
                        this.loadScriptsAsync(url, b);
                        // } else if (typeof url === 'object' && typeof url.url === 'string'){
                        //     this.loadScript(url.url);
                        //     //TODO registrovat event
                    } else if (typeof url === 'string') {
                        this.loadScript(url);
                    } else {
                        for (var i = 0; i < url.length; i++) {
                            if (typeof url[i] === 'function') {
                                url[i]();
                            } else {
                                this.loadScript(url[i]);
                            }
                        }
                    }
                }
            }
        } catch (e) {
            this.error(e);
        }
    };

    window.cpexLoader.init = function () {
        if (typeof this.scriptsToLoad !== 'undefined'
            && Array.isArray(this.scriptsToLoad)
            && this.scriptsToLoad.length > 0
        ) {
            if (this.useSequential) {
                this.log('Loader uses loadScriptsSequential');
                this.loadScriptsSequential(this.scriptsToLoad);
            } else {
                this.log('Loader uses loadScriptsAsync');
                this.shouldFireEvent(this.scriptsToLoad);
                this.loadScriptsAsync(this.scriptsToLoad);
            }
        } else {
            if (typeof this.scriptsToLoadPath === 'undefined' || !this.scriptsToLoad) {
                this.error('Loader: missing scriptsToLoad');
            } else {
                this.loadScript(this.scriptsToLoadPath, this.init.bind(this));
            }
        }
    };

    window.cpexLoader.shouldFireEvent = function (scripts) {
        if (scripts.indexOf('https://cdn.cpex.cz/skin/v2/cpex-skin.js') < 0
            && scripts.indexOf('https://cdn.cpex.cz/stage/skin/v2/cpex-skin.js') < 0
        ) {
            cpexLoader.emitEvent('cpexSkinObject');
            // there is HTML5 script inside of skin script
            scripts.push('https://cdn.cpex.cz/html5/v1/cpex-html5.js');
        } else if (
            (scripts.indexOf('https://cdn.cpex.cz/skin/v2/cpex-skin.js') > -1
                || scripts.indexOf('https://cdn.cpex.cz/stage/skin/v2/cpex-skin.js') > -1
            ) && typeof cpexSkin === 'function'
        ) {
            cpexLoader.log('cpexSkin event can be fired');
            // cpexLoader.emitEvent('cpexSkin');
        }
    };

    var versionPaths = {
        production: 'https://cdn.cpex.cz/',
        stage: 'https://cdn.cpex.cz/stage/',
        test: 'https://test.cpex.cz/'
    };
    var files = {
        'cmp': 'cmp/v2/cpex-cmp.min.js',
        'caf-settings': 'caf/v1/settings/',
        'skin-base': 'skin/v2/base/',
        'skin': 'skin/v2/cpex-skin.js',
        'sas-tracker': 'caf/v1/catch/sas-tracker-min.js',
        'prebid': 'hb/prebid/prebid.min.js',
        'hb-settings': 'hb/settings/',
        'hb-wrapper': 'hb/wrappers/',
        'aam': 'aam/cpex-aam-min.js',
        'timer': 'timer/v1/cpex-timer.js'
    };

    // vezme data z Launch Extension a upravi je pro nacitani loaderem.
    if (typeof cpexLoader.extensionData !== 'undefined') {
        cpexLoader.log('extensionData');
        cpexLoader.log(cpexLoader.extensionData);
        try {
            var hbWrapper = "cpex-hb-sas-tracker.js";
            var publisher = 'cpex';
            if (typeof cpexLoader.extensionData.extensionSettings !== 'undefined') {
                // per publisher settings
                var extensionSettings = cpexLoader.extensionData.extensionSettings;
                hbWrapper = extensionSettings.hbWrapper || hbWrapper;
                publisher = extensionSettings.publisher || publisher;
                // for schain id
                if (extensionSettings.schainId) {
                    window.cpexSupplyChainId = extensionSettings.schainId;
                }
                // for aam configuration
                if (extensionSettings.aamConfig) {
                    window.cpex_AAM_conf = Function('"use strict"; return (' + extensionSettings.aamConfig + ')')();
                }
            }
            if (typeof cpexLoader.extensionData.settings !== 'undefined') {
                // per site settings
                var settings = cpexLoader.extensionData.settings;
                if (typeof settings.usePath !== 'undefined' && settings.usePath
                    && typeof settings.scriptsToLoadPath !== 'undefined'
                    && settings.scriptsToLoadPath
                ) {
                    cpexLoader.scriptsToLoadPath = settings.scriptsToLoadPath;
                } else {
                    if (typeof settings.stepsArray !== 'undefined' && Array.isArray(settings.stepsArray)) {
                        console.log("steps: ", settings.stepsArray);
                        for (var i = 0; i < settings.stepsArray.length; i++) {
                            var step = settings.stepsArray[i];
                            var path = (typeof step.version !== 'undefined' && typeof versionPaths[step.version] === 'string') ? versionPaths[step.version] : '';
                            path += (typeof step.type !== 'undefined' && typeof files[step.type] === 'string') ? files[step.type] : '';
                            if (typeof step.jsFile !== 'undefined') {
                                path += publisher + '/' + step.jsFile;
                            }
                            if (typeof step.type !== 'undefined' && step.type === 'hb-wrapper') {
                                path += hbWrapper;
                            }
                            // custom script path
                            if (typeof step.path === 'string' && step.path) {
                                path = step.path;
                            }
                            if (typeof step.loadLimit !== 'undefined') {
                                if (step.loadLimit === 'never') {
                                    continue;
                                } else {
                                    if (step.type === 'cmp-id') {
                                        window.oneTrustId = step.parameter;
                                    }
                                    cpexLoader.scriptsToLoad.push(path);
                                }
                            }
                        }
                    }
                }
            }
        } catch (e) {
            cpexLoader.error(e);
        }
    }

    function readyToInit() {
        if (typeof window.cpexLoader.scriptsToLoadPath !== 'undefined' && window.cpexLoader.scriptsToLoadPath) {
            window.cpexLoader.settingsDone = false;
            window.cpexLoader.loadScript(window.cpexLoader.scriptsToLoadPath, function () {
                window.cpexLoader.settingsDone = true;
                window.cpexLoader.init();
            });
        } else {
            window.cpexLoader.settingsDone = true;
            window.cpexLoader.init();    
        }    
    }

    // Check for IE user agent and add polyfills
    if (window.navigator.userAgent.match(/(MSIE|Trident)/)) {
        window.performance = window.performance || { now: function () { } };
        window.cpexLoader.loadScript("https://polyfill.io/v3/polyfill.min.js?features=CustomEvent%2CPromise%2CPromise.prototype.finally%2CMap%2CSet%2CSymbol%2CSymbol.asyncIterator%2CSymbol.for%2CSymbol.hasInstance%2CSymbol.isConcatSpreadable%2CSymbol.iterator%2CSymbol.keyFor%2CSymbol.match%2CSymbol.prototype.description%2CSymbol.replace%2CSymbol.search%2CSymbol.species%2CSymbol.split%2CSymbol.toPrimitive%2CSymbol.toStringTag%2CSymbol.unscopables%2CArray.prototype.includes%2CString.prototype.repeat%2CNumber.isInteger", readyToInit)
    } else {
        readyToInit();
    }
}
